<template>
    <div>
        <NebulaFieldGroup class="invite-input invite-input__field-group">
            <template v-slot:formElement>
                <NebulaInputWithButtons borderRadiusSmall>
                    <template v-slot:input>
                        <NebulaInput
                            :placeholder="$t('Enter email, or paste multiple emails separated by commas')"
                            v-model="inputGroupData.value"
                            @input="checkLength"
                        />
                    </template>
                    <template v-slot:buttonElementEnd>
                        <NebulaButton type="ghost" :text="inviteButtonText" @click="convertToInvites" />
                    </template>
                </NebulaInputWithButtons>
                <div class="nebula-form-helper">
                    <div class="nebula-form-helper__text">
                        <slot name="helper">
                        {{ $t('All users will be added as members. There is a maximum of 30 employee invites at a time.') }}
                        </slot>
                    </div>
                    <div
                        class="nebula-form-helper__text invite-input__counter"
                        :class="{'invite-input__counter--warn': inviteLength > INVITE_MAX}"
                        v-if="showCounter && inviteLength > 0"
                    >
                        {{ inviteLength }}/{{ INVITE_MAX }}
                    </div>
                </div>
            </template>
        </NebulaFieldGroup>
    </div>
</template>

<script>
import {
    NebulaButton,
    NebulaInput,
    NebulaInputWithButtons,
    NebulaFieldGroup,
} from '@discoveryedu/nebula-components';
import { INVITE_MAX } from '@/constants';

export default {
    name: 'InviteInput',
    components: {
        NebulaButton,
        NebulaInputWithButtons,
        NebulaFieldGroup,
        NebulaInput,
    },
    data() {
        return {
            inviteLength: null,
            INVITE_MAX,
        };
    },
    props: {
        inputGroupData: {
            type: Object,
        },
        updateErrors: {
            type: Function,
        },
        resetErrors: {
            type: Function,
        },
        stagedFromParent: {
            type: Array,
            default: () => [],
        },
        inviteCallback: {
            type: Function,
        },
        inviteButtonText: {
            type: String,
            default: 'Invite Users',
        },
        showCounter: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        checkLength(input) {
            if (!input) {
                this.inviteLength = null;
                return;
            }
            const emails = input.split(',').filter((each) => each.length > 0);
            this.inviteLength = emails.length;
        },
        validateEmail(string) {
            const regex = '^[A-Za-z0-9+\'._%$-]+@[A-Za-z0-9.-]+[.][A-Za-z]+$';
            return string.match(regex);
        },
        convertToInvites() {
            const inviteString = this.inputGroupData.value;
            const emails = inviteString.split(',').map((each) => each.trim().toLowerCase());

            const staged = [];
            this.resetErrors();
            emails.forEach((email) => {
                const isValid = Boolean(this.validateEmail(email));

                // bail if blank
                if (email.length < 1) {
                    return;
                }
                if (!isValid) {
                    this.updateErrors('invalid', email);
                    return;
                }

                if (staged.includes(email) || this.stagedFromParent.includes(email)) {
                    this.updateErrors('duplicate', email);
                    return;
                }

                if (this.inviteCallback) {
                    this.inviteCallback(email);
                    this.$emit('callback-triggered');
                }
                staged.push(email);
            });

            this.$emit('stage-invites', staged);
        },
    },
};
</script>

<style lang="stylus">
.invite-input {
    .nebula-input-with-buttons__input {
        flex: 1;
    }
    &__field-group {
        max-width: unset;
        width: 100%;
    }

    &__counter {
        text-align: right;
        &--warn {
            font-weight: 600;
            color: $nebula-color-feedback-error-600;
        }
    }
}
</style>
